.MigrationError {
  &-help {
    margin-top: var(--xs);

    &-link {
      color: var(--red) !important;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
