@import '@core/styles/mixins/ngz';
@import '@core/styles/mixins/spinner';
@import '@core/styles/vars';

.rm-Guides {
  @include updated-at;

  // SuperHub consumer handles displaying its loading state with `ui/Spinner`
  &_loading:not(.rm-Guides-SuperHub) {
    @include spinner;
  }

  .rm-Article {
    @media (min-width: $ipad-mini-portrait + 1) {
      max-width: calc(100% - var(--hub-sidebar-width));
    }

    @media (max-width: $ipad-mini-portrait) {
      max-width: 100%;
    }
  }

  // everything to make the old markup work
  .row {
    display: flex;

    [class^='col-xs']:first-child {
      flex: 1 1 auto;
    }
  }

  #content-head {
    border-bottom: 1px solid var(--color-border-default);
    padding-bottom: 30px;

    h1 {
      margin-bottom: 0;
      margin-top: 0;
    }

    .excerpt {
      margin-top: 15px;
    }

    p {
      font-size: 18px;
      margin-bottom: 0;
      margin-top: 15px;
    }
  }

  .content-body {
    padding-top: 30px;
  }

  #content-head .col-xs-9 {
    max-width: var(--hub-main-max-width);
  }

  #content-head .col-xs-3 {
    box-sizing: border-box;
    flex: 0 0 var(--hub-toc-width);
    padding-left: $lg;
    padding-right: $md;

    @media (max-width: $ipad-pro-10-landscape) {
      flex: 0 0 auto;
    }

    .icon {
      color: var(--color-text-minimum-icon);
      font-size: 16px;
      text-align: center;
      width: 23px;
    }
  }

  a.suggestEdits {
    // mirroring our ToC styles
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
    font-size: 13px;

    @media (max-width: $ipad-pro-10-landscape) {
      font-size: 0;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--color-text-minimum-hover);
    }
  }

  #content-container {
    margin: 0;
    max-width: none;
  }

  .content-body {
    flex-shrink: 1;
    max-width: calc(100% - var(--hub-toc-width));
    width: var(--hub-main-max-width);

    &:only-child {
      max-width: 100%;
    }

    @media (max-width: $ipad-pro-10-landscape) {
      max-width: 100%;
    }

    @media (max-width: $iphone-plus) {
      overflow: hidden;
    }
  }

  .grid-container-fluid {
    display: flex;
  }

  // This is only needed because of the old api explorer layout
  // once everyone is on the redesign we can remove it!
  hr.NextStepsDivider {
    margin-left: 0 !important;
  }
}
